<template>
    <div>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>产品管理</el-breadcrumb-item>
            <el-breadcrumb-item>产品订单管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <!-- 筛选 -->
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">订单筛选</span>
                </el-col>
            </el-row>
            <el-form
                :model="queryForm"
                class="filter_Form"
                label-width="120px"
                ref="queryFormRef"
            >
                <el-row :gutter="10">
                    <el-col :span="8">
                        <el-form-item label="赛季" prop="season">
                            <el-select
                                placeholder="请选择赛季"
                                style="width: 100%"
                                clearable
                                v-model="queryForm.season"
                            >
                                <el-option
                                    v-for="item in dict_season"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="所属赛区" prop="districtId">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.districtId"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_district"
                                    :key="item.id"
                                    :label="item.districtName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="8">
                        <el-form-item label="学校省市区" prop="schoolArea">
                            <el-cascader
                                placeholder="请选择"
                                clearable
                                v-model="queryForm.schoolArea"
                                ref="schoolAreaRef"
                                :options="areasData"
                                style="width: 100%"
                                :props="{
                                    expandTrigger: 'hover',
                                    label: 'name',
                                    value: 'id',
                                    children: 'areas',
                                }"
                                @change="provincesHandleChange"
                            ></el-cascader>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="8">
                        <el-form-item label="学校" prop="schoolId">
                            <el-select
                                ref="schoolSelectRef"
                                style="width: 100%"
                                placeholder="请选择"
                                v-model="queryForm.schoolId"
                                clearable
                                filterable
                            >
                                <el-option
                                    v-for="item in provincesSchoolList"
                                    :key="item.id"
                                    :label="item.schoolName"
                                    :value="item.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <!-- <el-col :span="9">
            <el-form-item label="起止时间" prop="dateTimeRange">
              <el-date-picker
                v-model="queryForm.dateTimeRange"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="timestamp"
                clearable
              >
              </el-date-picker>
            </el-form-item>
          </el-col> -->
                    <el-col :span="8">
                        <el-form-item label="姓名" prop="userName">
                            <el-input
                                v-model="queryForm.userName"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="手机号" prop="phone">
                            <el-input
                                v-model="queryForm.phone"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="订单编号" prop="orderNo">
                            <el-input
                                v-model="queryForm.orderNo"
                                placeholder="请输入"
                                clearable
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="8">
                        <el-form-item label="订单来源" prop="orderSource">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.orderSource"
                                clearable
                            >
                                <el-option
                                    v-for="item in order_source"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="订单状态" prop="orderStatus">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.orderStatus"
                                clearable
                            >
                                <el-option
                                    v-for="item in order_status"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="产品名称" prop="productFlags">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.productFlags"
                                multiple
                                clearable
                            >
                                <el-option
                                    v-for="item in productList"
                                    :key="item.flag"
                                    :label="item.productName"
                                    :value="item.flag"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="支付类型" prop="payType">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.payType"
                                clearable
                            >
                                <el-option
                                    v-for="item in order_payType"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="商户类型" prop="merchantType">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.merchantType"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_merchantType"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是非赛区" prop="nullDistrict">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.nullDistrict"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_nullDistrictStates"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="下单时间" prop="orderTime">
                            <el-date-picker
                                v-model="orderTimeRange"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                @change="orderTimeChange"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否发货" prop="sendStatus">
                            <el-select
                                placeholder="请选择"
                                style="width: 100%"
                                v-model="queryForm.sendStatus"
                                clearable
                            >
                                <el-option
                                    v-for="item in dict_boolData"
                                    :key="item.dictCode"
                                    :label="item.dictLabel"
                                    :value="item.dictValue"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" type="flex" justify="end">
                    <el-col :span="2">
                        <el-button
                            type="primary"
                            style="width: 100%"
                            @click="getDataList('search')"
                            >查询</el-button
                        >
                    </el-col>
                    <el-col :span="2">
                        <el-button
                            type="primary"
                            style="width: 100%"
                            @click="reset"
                            >重置</el-button
                        >
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <!-- 订单列表 -->
        <el-card class="list_card" style="margin-top: 20px">
            <el-row class="title_row">
                <el-col :span="12">
                    <div class="list_title title_class">订单列表</div>
                </el-col>
                <el-col :span="12">
                    <div style="text-align: right">
                        <el-button type="primary" @click="showModel"
                            >批量发货</el-button
                        >
                        <el-button type="primary" @click="exportOrderList"
                            >导出订单</el-button
                        >
                    </div>
                </el-col>
            </el-row>
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="dataList"
                border
                stripe
            >
                <el-table-column label="序号" type="index"></el-table-column>
                <el-table-column
                    label="商户类型"
                    prop="merchantType"
                    :formatter="merchantTypeFormatter"
                ></el-table-column>
                <el-table-column label="赛季" prop="season"></el-table-column>
                <el-table-column
                    label="赛区"
                    prop="districtName"
                ></el-table-column>
                <el-table-column
                    label="订单名称"
                    prop="orderName"
                    width="150px"
                ></el-table-column>
                <el-table-column
                    label="订单号"
                    prop="orderNo"
                ></el-table-column>
                <el-table-column
                    label="用户信息"
                    prop="userName"
                    width="120px"
                >
                <template slot-scope="scope">
                        <div style="display: flex;flex-direction: column; align-items: center">
                          <div>{{ scope.row.userName }} {{ scope.row.sex === "1" ? '男' : '女' }}</div>
                          <div>{{ scope.row.phone }}</div>
                          <div>年级：{{ scope.row.grade }}</div>
                        </div>
                    </template>
              </el-table-column>
                <el-table-column
                    label="学校名称"
                    prop="schoolName"
                ></el-table-column>
                <el-table-column
                    label="订单金额"
                    prop="orderAmount"
                    width="100px"
                >
                <template slot-scope="scope">
                        <div style="display: flex;flex-direction: column; align-items: center">
                          <div style="color: green;">订单：{{ scope.row.orderAmount }}</div>
                          <div style="color: red">实付：{{ scope.row.paymentAmount ? scope.row.paymentAmount : '-'}}</div>
                        </div>
                    </template>
              </el-table-column>
                <!-- <el-table-column
          label="优惠金额"
          prop="discountAmount"
        ></el-table-column> -->
                <!-- <el-table-column
                    label="实付金额"
                    prop="paymentAmount"
                ></el-table-column> -->
                <el-table-column
                    label="订单来源"
                    prop="orderSource"
                    :formatter="sourceFormatter"
                >
                </el-table-column>
                <el-table-column
                    label="下单时间"
                    prop="orderTime"
                ></el-table-column>
                <el-table-column label="是否发货" prop="sendStatus" width="100px">
                    <template slot-scope="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.sendStatus"
                            size="mini"
                            >已发货</el-tag
                        >
                        <el-tag type="warning" v-else size="mini"
                            >未发货</el-tag
                        >
                    </template>
                </el-table-column>
                <!-- <el-table-column label="是否寄送" prop="isSend" width="100px">
          <template slot-scope="scope">
            <el-tag
              type="success"
              v-if="scope.row.isSend + '' === '1'"
              size="mini"
              >需要寄送</el-tag
            >
            <el-tag type="warning" v-else size="mini">无需寄送</el-tag>
          </template>
        </el-table-column> -->
                <el-table-column
                    label="订单状态"
                    prop="orderStatus"
                    width="100px"
                    :formatter="statusFormatter"
                >
                    <template slot-scope="scope">
                        <el-tag
                            :type="findStatus(scope.row.orderStatus).type"
                            size="mini"
                            >{{
                                findStatus(scope.row.orderStatus).dictLabel
                            }}</el-tag
                        >
                    </template>
                </el-table-column>
                <el-table-column label="退费时间" prop="refundTime">
                    <template slot-scope="scope">
                        <span style="color: rgb(255, 0, 0)">{{
                            scope.row.refundTime
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120px" fixed="right">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="showProductDetailDialog(scope.row)"
                            >查看详情</el-button
                        >
                        <!-- 待发货 -->
                        <el-button
              v-if="
                (scope.row.orderStatus &&
                scope.row.orderStatus + '' === '4') && (scope.row.isSend &&
                scope.row.isSend + '' === '1') && scope.row.sendStatus !== true
              "
              type="text"
              size="small"
              @click="showSendProductDetailDialogClicked(scope.row)"
              >发货</el-button
            >
                        <!-- 订单流程完善后，用户可在小程序端确认收货，或超过一定时效自动确认收货 -->
                        <!-- <el-button
              v-if="
                scope.row.orderStatus &&
                scope.row.orderStatus + '' === '3' &&
                scope.row.isSend + '' === '1'
              "
              type="text"
              size="small"
              @click="confirmReceiptGoods(scope.row)"
              >用户已收货</el-button
            > -->
                        <!-- <el-button type="text" size="small" v-if="scope.row.orderStatus === 5" @click="showAuditFn(scope.row)">退款审核</el-button>
            <el-button type="text" size="small" v-if="scope.row.orderStatus === 11" @click="showConfirmFn(scope.row)">确认收货</el-button>
            <el-button type="text" size="small" v-if="scope.row.orderStatus === 11" @click="showRefuseFn(scope.row)">拒绝收货</el-button>
            <el-button type="text" size="small" v-if="scope.row.orderStatus === 13" @click="refundSuccessFn(scope.row)">财务已打款</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryForm.pageNum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <!-- 审核弹窗 -->
        <el-dialog
            title="审核"
            :visible.sync="auditVisible"
            @close="hideAuditFn"
            width="500px"
        >
            <el-form label-width="100px" style="width: 100%">
                <el-form-item label="退费类型" prop="">
                    <el-tag type="primary" size="mini">{{
                        refundInfo.refundType === 0 ? "仅退款" : "退货退款"
                    }}</el-tag>
                </el-form-item>
                <el-form-item
                    label="货物状态"
                    prop=""
                    v-if="refundInfo.refundType === 0"
                >
                    <el-tag
                        :type="
                            refundInfo.cargoStatus === 0 ? 'danger' : 'success'
                        "
                        size="mini"
                        >{{
                            refundInfo.cargoStatus === 0
                                ? "未收到货"
                                : "已收到货"
                        }}</el-tag
                    >
                </el-form-item>
                <el-form-item
                    label="退货方式"
                    prop=""
                    v-if="refundInfo.refundType === 1"
                >
                    <el-tag type="warning" size="mini">自行寄回</el-tag>
                </el-form-item>
                <el-form-item label="退费金额" prop="">
                    <div class="price">¥{{ refundInfo.refundApplyAmount }}</div>
                </el-form-item>
                <el-form-item label="退费原因" prop="" class="bb">
                    <div class="reason-area">
                        {{ refundInfo.refundApplyReason }}
                    </div>
                </el-form-item>

                <el-form-item label="审核" prop="">
                    <el-radio
                        v-model="refundInfo.refundApplyForStatus"
                        :label="1"
                        @input="auditRadioChange"
                        >通过</el-radio
                    >
                    <el-radio
                        v-model="refundInfo.refundApplyForStatus"
                        :label="2"
                        @input="auditRadioChange"
                        >不通过</el-radio
                    >
                </el-form-item>
                <el-form-item
                    label="退费金额"
                    prop=""
                    v-if="
                        refundInfo.refundApplyForStatus === 1 &&
                        refundInfo.refundType === 0
                    "
                >
                    ¥
                    <el-input
                        style="width: 100px"
                        type="text"
                        v-model="refundApplyAmount"
                    />
                </el-form-item>
                <el-form-item
                    label="原因"
                    prop=""
                    v-if="
                        refundInfo.refundApplyForStatus === 2 ||
                        (refundInfo.refundApplyForStatus === 1 &&
                            refundInfo.refundType === 0)
                    "
                >
                    <el-input
                        type="textarea"
                        :rows="3"
                        placeholder="请输入审核不通过原因"
                        v-model="refundInfo.reasonRefundReview"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="auditVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleToAudit"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 确认收货弹窗 -->
        <el-dialog
            title="确认收货"
            :visible.sync="confirmVisible"
            @close="hideConfirmFn"
            width="500px"
        >
            <el-form label-width="80px" style="width: 100%">
                <el-form-item label="申请退款" prop="">
                    ¥{{ refundInfo.refundApplyAmount }}
                    <el-button type="text" size="small" @click="changeSumFn"
                        >修改</el-button
                    >
                </el-form-item>
                <el-form-item label="实际退款" prop="" v-if="changeSum">
                    ¥
                    <el-input
                        type="text"
                        style="width: 100px"
                        v-model="refundApplyAmount"
                    ></el-input>
                </el-form-item>
                <el-form-item label="修改原因" prop="" v-if="changeSum">
                    <el-input
                        type="textarea"
                        :rows="3"
                        v-model="confirmReceiptGoodsReason"
                        placeholder="请输入原因"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button
                    type="primary"
                    @click="sellerConfirmsReceiptOfGoodsFn(1)"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 拒绝收货弹窗 -->
        <el-dialog
            title="拒绝收货"
            :visible.sync="refuseVisible"
            @close="hideRefuseFn"
            width="500px"
        >
            <el-form label-width="80px" style="width: 100%">
                <el-form-item label="申请退款" prop="">
                    ¥{{ refundInfo.refundApplyAmount }}
                </el-form-item>
                <el-form-item label="拒绝原因" prop="">
                    <el-input
                        type="textarea"
                        :rows="3"
                        placeholder="请输入原因"
                        v-model="confirmReceiptGoodsReason"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="refuseVisible = false">取 消</el-button>
                <el-button
                    type="primary"
                    @click="sellerConfirmsReceiptOfGoodsFn(0)"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 填写快递信息弹窗 -->
        <el-dialog
            title="快递信息"
            :visible.sync="showSendProductDetailDialogVisible"
            @close="showSendProductDetailDialogClose"
            width="500px"
        >
            <el-form
                :model="sendProductForm"
                label-width="100px"
                ref="sendProductFormRef"
                :rules="sendProductInfoRules"
                style="width: 100%"
            >
                <el-form-item label="快递公司" prop="expressType">
                    <el-select
                        placeholder="请选择"
                        v-model="sendProductForm.expressType"
                        style="width: 100%"
                    >
                        <el-option
                            v-for="item in dict_express"
                            :key="item.dictValue"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="快递单号" prop="expressNumber">
                    <el-input
                        type="textarea"
                        v-model="sendProductForm.expressNumber"
                        placeholder="请输入"
                    ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showSendProductDetailDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="subSendProductMessage"
                    >确 定</el-button
                >
            </span>
        </el-dialog>

        <!-- 批量发货 -->
        <el-dialog
            title="批量发货"
            :visible.sync="dialogVisible"
            @close="handleToCloseModel"
            width="550px"
        >
            <el-row>
                <el-col :span="24" class="row"
                    >1. 先筛选出需要发货的订单并导出</el-col
                >
                <el-col :span="24" class="row"
                    >2.
                    编辑导出的文件，在最后两列补充“快递公司”和“快递单号”并上传</el-col
                >
                <el-col :span="24" class="row"
                    >3.
                    “快递公司”一列填写示例：顺丰快递/德邦快递/中通快递/申通快递/邮政快递/韵达快递/汇通快递/圆通快递/京东快递。暂不识别其他快递</el-col
                >
            </el-row>

            <el-upload
                ref="uploadFileRef"
                :action="uploadInfo.uploadUrl"
                :headers="uploadInfo.headerObj"
                :before-upload="beforeUpload"
                :limit="1"
                :on-success="uploadSuccess"
                :on-error="uploadError"
            >
                <el-button type="primary">点击上传</el-button>
            </el-upload>
        </el-dialog>
    </div>
</template>

<script>
import { getTbOrderList, excelTbOrderList, getAllAreaList, queryDistrictPage, fillInTheTrackingNumber, getFlagListData, executionOrderCompletion, getSchoolListWithCity, orderRefundReviewInfo, orderRefundReview, sellerConfirmsReceiptOfGoods, financialAuditRefund } from '@/http/api'
export default {
  data () {
    return {
      showSendProductDetailDialogVisible: false,
      sendProductForm: {
        expressType: '',
        expressNumber: ''
      },
      productList: [],
      areasData: [],
      dataList: [],
      e_sex: this.$userInfo.getDataList(this, 'e_sex').then(value => { this.e_sex = value }),
      dict_express: this.$userInfo.getDataList(this, 'express_type').then(value => { this.dict_express = value }),
      dict_district: [],
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      dict_merchantType: this.$userInfo.getDataList(this, 'dict_merchantType').then(value => { this.dict_merchantType = value }),
      order_source: this.$userInfo.orderSource(),
      order_status: this.$userInfo.orderStatus(),
      dict_boolData: this.$userInfo.dict_boolData(),
      dict_nullDistrictStates: this.$userInfo.nullDistrictStates(),
      order_payType: this.$userInfo.order_payType(),
      sendProductInfoRules: {
        expressType: [{ required: true, message: '请选择', trigger: 'change' }],
        expressNumber: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      provincesSchoolList: [],
      queryForm: {
        userName: '',
        orderSource: '',
        orderStatus: '',
        season: this.$chnEngStatusCode.defaultSeason,
        sendStatus: null,
        phone: '',
        pageNum: 1,
        pageSize: 10,
        orderNo: null,
        orderStartTime: '',
        orderEndTime: '',
        nullDistrict: false
      },
      total: 0,
      orderTimeRange: [],
      dialogVisible: false,
      uploadInfo: {
        uploadUrl: `${this.$http.defaults.baseURL}order/importSendGoodsTbOrderList`,
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      auditVisible: false,
      confirmVisible: false,
      refuseVisible: false,
      changeSum: false,
      refundInfo: {},
      refundApplyAmount: '', // 确认收货退款金额
      confirmReceiptGoodsReason: '' // 确认/拒绝收货原因
    }
  },
  getAllAreaList () {
    getAllAreaList({}).then((res) => {
      this.areasData = res.data
    }).catch((err) => {
      console.log('返回的err', err)
    })
  },
  activated () {
    if (this.$route.query.status) {
      this.queryForm.orderStatus = this.$route.query.status
    } else {
      this.queryForm.orderStatus = ''
    }
    this.getDataList()
  },
  created () {
    this.getDistrictList()
    this.getFlagList()
    this.getSchoolListData()
  },
  // 再次激活的钩子
  acitvated () {
    // 如果isPush为true，证明不是详情页返回的，否则就是从详情页返回的
    if (this.$route.meta.isPush === true) {
      // 重置这个页面的信息
      this.reset()
    } else {
      // 重置isPush的状态
      this.$route.meta.isPush = true
    }
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取产品标识列表
    getFlagList () {
      getFlagListData(this.queryForm).then((res) => {
        console.log('getFlagListData', res)
        this.productList = res.data
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取订单列表
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
      }
      getTbOrderList(this.queryForm).then((res) => {
        console.log('getTbOrderList', res)
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取学校List
    getSchoolListData (schoolArea) {
      getSchoolListWithCity({ pageNum: 1, pageSize: 10000 }).then((res) => {
        console.log('getSchoolList', res)
        this.provincesSchoolList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 重置搜索条件
    reset () {
      this.orderTimeRange = []
      this.queryForm.orderStartTime = ''
      this.queryForm.orderEndTime = ''
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    // 查看订单详情
    showProductDetailDialog (data) {
      this.$route.meta.isPush = false
      this.$router.push(
        {
          path: '/product_Management/product_detail_management',
          query: {
            order_no: data.orderNo,
            user_id: data.userId
          }
        }
      )
    },

    // 显示填写快递信息弹窗
    showSendProductDetailDialogClicked (rowData) {
      console.log('rowData', rowData)
      this.sendProductForm.userId = rowData.userId
      this.sendProductForm.orderNo = rowData.orderNo
      this.showSendProductDetailDialogVisible = true
    },
    // 提交快递信息
    subSendProductMessage () {
      this.$refs.sendProductFormRef.validate(valid => {
        if (!valid) return
        fillInTheTrackingNumber(this.sendProductForm).then((res) => {
          this.showSendProductDetailDialogVisible = false
          this.$message.success('操作成功')
          this.getDataList()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    // 确认收货
    confirmReceiptGoods (rowData) {
      this.$confirm('是否确认用户已收到货物。', '提示', {
        confirmButtonText: '确认收到',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var formData = {
          orderNo: rowData.orderNo,
          userId: rowData.userId
        }
        executionOrderCompletion(formData).then((res) => {
          console.log('executionOrderCompletion', res)
          this.$message.success('操作成功')
          this.getDataList()
        }).catch((err) => {
          console.log('err', err)
        })
        // 确认收货
      }).catch(() => {
      })
    },
    showSendProductDetailDialogClose () {
      this.$refs.sendProductFormRef.resetFields()
    },
    // 导出订单
    exportOrderList () {
      // 导出订单
      excelTbOrderList(this.queryForm).then((res) => {
        window.location.href = res.data
      }).catch((err) => {
        console.log('err', err)
      })
    },
    showModel () {
      this.dialogVisible = true
    },
    handleToCloseModel () {
      this.dialogVisible = false
    },
    beforeUpload (file) {
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.$message.error('请选择excel文件上传')
        return false
      }
    },
    uploadSuccess (res) {
      if (res.code === 200) {
        this.$message.success('批量发货成功')
        this.dialogVisible = false
        this.getDataList('search')
      } else {
        this.$message.error(res.msg)
      }
      this.$refs.uploadFileRef.clearFiles()// 清除文件
    },
    uploadError (error) {
      console.log('uploadError', error)
    },
    downloadTemp () {
      const params = { ...this.queryForm, ...{ orderStatus: '2' } }
      var downLoadUrl = this.$env.baseIP + 'order/excelTbOrderList'
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', params)
    },
    sourceFormatter (data) {
      var temp = '未设置'
      for (const key in this.order_source) {
        if (Object.hasOwnProperty.call(this.order_source, key)) {
          const element = this.order_source[key]
          if (element.dictValue === data.orderSource + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    statusFormatter (data) {
      var temp = '未设置'
      for (const key in this.order_status) {
        if (Object.hasOwnProperty.call(this.order_status, key)) {
          const element = this.order_status[key]
          if (element.dictValue === data.orderStatus + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },

    merchantTypeFormatter (data) {
      var temp = '未分账'
      for (const key in this.dict_merchantType) {
        if (Object.hasOwnProperty.call(this.dict_merchantType, key)) {
          const element = this.dict_merchantType[key]
          if (element.dictValue === data.merchantType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    findStatus (status) {
      console.log('status', status)
      console.log('this.order_status', this.order_status)
      return this.order_status.find(item => item.dictCode === status)
    },
    orderTimeChange (e) {
      if (e.length) {
        this.queryForm.orderStartTime = this.$moment(e[0]).format('YYYY-MM-DD HH:mm:ss')
        this.queryForm.orderEndTime = this.$moment(e[1]).format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryForm.orderStartTime = ''
        this.queryForm.orderEndTime = ''
      }
    },

    // 审核退款
    showAuditFn (item) {
      this.refundInfo = item
      // 获取退款信息
      orderRefundReviewInfo({ userId: item.userId, orderNo: item.orderNo }).then(res => {
        this.auditVisible = true
        this.refundInfo = { ...this.refundInfo, ...res.data }
      })
    },
    hideAuditFn () {
      this.auditVisible = false
    },
    auditRadioChange (e) {
      if (e === 1) {
        this.refundApplyAmount = this.refundInfo.refundApplyAmount
      }
    },
    handleToAudit () {
      const params = {
        orderNo: this.refundInfo.orderNo,
        reasonRefundReview: this.refundInfo.reasonRefundReview || '',
        refundApplyForStatus: this.refundInfo.refundApplyForStatus,
        refundAuditAmount: this.refundApplyAmount || this.refundInfo.refundApplyAmount,
        userId: this.refundInfo.userId
      }
      if (params.refundApplyForStatus === 1 || (params.refundApplyForStatus === 2 && params.reasonRefundReview)) {
        orderRefundReview(params).then(res => {
          if (res.code === 200) {
            this.$message.success('已审核')
            this.getDataList()
            this.hideAuditFn()
          }
        })
      }
    },

    // 确认收货
    showConfirmFn (item) {
      this.refundInfo = item
      this.confirmReceiptGoodsReason = ''
      this.refundApplyAmount = ''
      this.changeSum = false
      orderRefundReviewInfo({ userId: item.userId, orderNo: item.orderNo }).then(res => {
        this.confirmVisible = true
        this.refundInfo = { ...this.refundInfo, ...res.data }
      })
    },
    hideConfirmFn () {
      this.confirmVisible = false
    },
    /**
                 * 确认/拒绝收货
                 * @param {操作类型} type 1确认收货 0拒绝收货
                 */
    sellerConfirmsReceiptOfGoodsFn (type) {
      const params = {
        confirmReceiptGoods: type,
        confirmReceiptGoodsReason: this.confirmReceiptGoodsReason,
        orderNo: this.refundInfo.orderNo,
        refundApplyAmount: this.refundApplyAmount || this.refundInfo.refundApplyAmount,
        userId: this.refundInfo.userId
      }
      sellerConfirmsReceiptOfGoods(params).then(res => {
        if (res.code === 200) {
          this.$message.success(`已${type === 1 ? '确认' : '拒绝'}收货`)
          this.getDataList()
          if (type === 1) {
            this.hideConfirmFn()
          } else {
            this.hideRefuseFn()
          }
        }
      })
    },

    // 退款成功
    refundSuccessFn (item) {
      this.$confirm('确认财务已打款？确认后用户将收到退款成功提示', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 调接口、刷新列表
        const params = {
          orderNo: item.orderNo,
          refundApplyForReason: '',
          refundApplyForStatus: 1,
          userId: item.userId
        }
        financialAuditRefund(params).then(res => {
          if (res.code === 200) {
            this.getDataList()
            this.$message.success('退款成功')
          }
        })
      }).catch(() => {
      })
    },

    // 拒绝收货
    showRefuseFn (item) {
      this.refundInfo = item
      this.confirmReceiptGoodsReason = ''
      this.refundApplyAmount = ''
      orderRefundReviewInfo({ userId: item.userId, orderNo: item.orderNo }).then(res => {
        this.refuseVisible = true
        this.refundInfo = { ...this.refundInfo, ...res.data }
      })
    },
    hideRefuseFn () {
      this.refuseVisible = false
    },

    changeSumFn () {
      this.changeSum = !this.changeSum
    },
    genderFormatter (data) {
      var temp = '未设置'
      for (const key in this.e_sex) {
        if (Object.hasOwnProperty.call(this.e_sex, key)) {
          const element = this.e_sex[key]
          console.log('element.dictValue', element.dictValue)
          if (element.dictValue + '' === data.sex + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  }
}
</script>

<style scoped>
.a-btn {
    color: #409eff;
    font-size: 14px;
}
.row {
    display: flex;
    margin-bottom: 12px;
}
.reason-area {
    background-color: #fafafa;
    border-radius: 12px;
    padding-left: 12px;
    padding-right: 12px;
    white-space: pre-wrap;
    /* line-height: 24px; */
}
.bb {
    border-bottom: 1px solid #eee;
    padding-bottom: 32px;
}
.price {
    color: red;
    font-size: 18px;
}
</style>
